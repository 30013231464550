import Image1 from "./images/image_1.png";
import Image2 from "./images/image_2.png";
import Image3 from "./images/image_3.png";
import Image4 from "./images/image_4.png";
import Image5 from "./images/image_5.png";
import Img from "./images/img.png";

export default [
  {
    id: 0,
    category: "Website",
    subtitle: "CV Website",
    description:
      "It is a personal CV website including various details related to my education and experiences.",
    image: Image1,
    address: "https://byeonghoonjeon.github.io/Personal-Webpage/index.html#",
    languages: "CSS, HTML",
  },
  {
    id: 1,
    category: "Website",
    subtitle: "Immunization status in Korea",
    description:
      "This website shows vaccinated population rate in South Korea using API provided by Korean government.",
    image: Image2,
    address: "https://byeonghoonjeon.github.io/immunizationStatus/",
    languages: "Java Script, CSS, HTML, API",
  },
  {
    id: 2,
    category: "Website",
    subtitle: "World Weather Window",
    description:
      "Users can enquire over 200,000 city's current weather. The website is working with API.",
    image: Image3,
    address: "https://byeonghoonjeon.github.io/Weather-of-Cities/",
    languages: "Java Script, CSS, HTML, API",
  },
  {
    id: 3,
    category: "Website",
    subtitle: "Nucamp",
    description: "It is a project website made during coding boot camp. ",
    image: Image4,
    address: "https://byeonghoonjeon.github.io/nucamp-webpage/",
    languages: "Java Script, SCSS, HTML, CSS",
  },
  {
    id: 4,
    category: "Website",
    subtitle: "Tindog",
    description: "Website without function",
    image: Image5,
    address: "https://byeonghoonjeon.github.io/Tindog/",
    languages: "CSS, HTML",
  },
  {
    id: 5,
    category: "Web App",
    subtitle: "BMI calculator",
    description: "It calculates BMI index based on user's body information",
    image: Img,
    address: "https://byeonghoonjeon.github.io/BMI-calc/",
    languages: "Java Script, HTML",
  },
  {
    id: 6,
    category: "Website",
    subtitle: "Rotten Potatoes",
    description: "Rotten Potatoes website",
    image: Img,
    address: "https://github.com/ByeonghoonJeon/rotten-potatoes",
    languages: "Java Script, Handlebars",
  },
  {
    id: 7,
    category: "Website",
    subtitle: "Emoji Dictionary",
    description: "React.js based Emoji Dictionary.",
    image: Img,
    address: "https://github.com/ByeonghoonJeon/Emoji-Dictionary",
    languages: "Java Script, CSS, HTML",
  },
  {
    id: 8,
    category: "Website (Under Construction)",
    subtitle: "Paraphrase",
    description: "A website for paraphrasing based on synonyms.",
    image: Img,
    address: "https://github.com/ByeonghoonJeon/paraphrase-website",
    languages: "Java Script, CSS, HTML",
  },
  {
    id: 9,
    category: "Website",
    subtitle: "Personal Blog",
    description: "Blog website for posting private journal etc.",
    image: Img,
    address: "https://github.com/ByeonghoonJeon/blogWebpage-v2",
    languages: "Java Script, EJS, CSS",
  },
  {
    id: 10,
    category: "Website",
    subtitle: "Todo List",
    description: "Website for making a memo of todo list.",
    image: Img,
    address: "https://github.com/ByeonghoonJeon/todoList-v2",
    languages: "Java Script, EJS, CSS, HTML",
  },
  {
    id: 11,
    category: "Website",
    subtitle: "Dice Challenge",
    description: "Dice game website",
    image: Img,
    address: "https://github.com/ByeonghoonJeon/Dice-Challange",
    languages: "Java Script, CSS, HTML",
  },
  {
    id: 12,
    category: "Website",
    subtitle: "Drum Play",
    description: "Web drum player",
    image: Img,
    address: "https://github.com/ByeonghoonJeon/drumKit",
    languages: "Java Script, CSS, HTML",
  },
  {
    id: 12,
    category: "Website",
    subtitle: "Drum Play",
    description: "Web drum player",
    image: Img,
    address: "https://github.com/ByeonghoonJeon/drumKit",
    languages: "Java Script, CSS, HTML",
  },
];
